import React, { useEffect } from "react";
import "../Blog/blogStyles.css";
import "./roadmap.css";
import "./LandingPageIndex.css";
import { Button, Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import { AddToCart, UpdateCartInfo } from "../../services/Services";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { RoadmapCourse } from "./constFile";
import { setRCartCookies, setRCartData } from "../../app/reducers/cartReducer";
import { setCommonLoading } from "../../app/reducers/courseReducer";
import Loading from "../loading";
import Layout from "../layout/layout";
import { FooterLandingPage, TestimonialTrailLanding } from "./LandingPage";
import PartnerView from "../partner.view";
import { ThereCards } from "../../component/Home/Banner";
import { OurStory } from "../about.view";
import FeedbackView from "../feedback.view";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";
import { WebinerUserEmailCheck } from "../../services/Webiner";
import { ContactUs } from '../../services/Services.js'
// default values for the header content
export const defaultData = {
  logoURI: "/assets/images/logo.webp",
  title: "Innovate Educate Grow Discover",
  mainTitle: "Industry-Driven Data Science Courses for Aspiring Professionals!",
  descriptionTitle:
    "Learn from industry experts and gain skills that are in demand by top companies",
  descriptionArray: [
    "Certifications: Get certified and recognized by top companies",
    "Job Assistance: 100% placement assistance with top-tier companies.",
    "Mentorship: 1-on-1 mentoring sessions with industry experts.",
  ],
  buttonTitle: "Enroll Now", //'Request Course Information'
};

// header 2 content
const header2Data = {
  buttonTitle: "Enroll Now",
};

function ContainerBody({ children, className }) {
  return <Container className={`body + ${className}`}>{children}</Container>;
}

// header content
/**
 *
 * @param {data:{
 * logoURI: string || {uri: string},
 * title: string,
 * mainTitle: string,
 * subTitle: string,
 * descriptionTitle: string,
 * descriptionArray: Array<string>,
 * buttonTitle: string,
 * }} param0 data for the header content
 * @param {onClick: function} param1 function to be called on button click
 * @returns
 */
export interface HeaderContentProps {
  onClick?: Function;
  data?: {
    logoURI?: string | { uri: string };
    title?: string;
    mainTitle?: string;
    subTitle?: string;
    descriptionTitle?: string;
    descriptionArray?: Array<string>;
  };
  buttonTitle?: string;
}
export function HeaderContent({
  onClick = () => { },
  data = defaultData,
  buttonTitle,
}: HeaderContentProps) {
  const { commonLoading } = useSelector((state) => state.course);

  if (commonLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="headerContainer landingPageIndexHeaderContainer">
          <div className="HeaderContent">
            {data.logoURI ? (
              <Image src={data.logoURI} rounded className="liveLogo" />
            ) : (
              <h1
                className="text-primary-color"
                style={{
                  fontSize: "3rem",
                }}
              >
                Live Sessions
              </h1>
            )}
            {data.title && (
              <h2 className="text-black mt-3 mb-3">{data.title || "Title"}</h2>
            )}
            {data.mainTitle && (
              <h1 className="text-primary-color  mb-4">
                {data.mainTitle || "Main Title"}
              </h1>
            )}
            {data.descriptionTitle && (
              <h2 className="text-black mt-3 mb-3 px-1 mb-5">
                {data.descriptionTitle || "description Title"}
              </h2>
            )}
            {data?.descriptionArray?.length > 0 && (
              <div className="text-start w-75">
                <Row>
                  {data.descriptionArray?.map((item, index) => (
                    <Col lg={6} sm={12} xs={12}>
                      <p className="text-black fs-5">
                        {" "}
                        <i className="fa fa-check" /> {item}
                      </p>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            <p className="text-black fs-5">Flexible batch timings.</p>
            <Button
              onClick={onClick()}
              href="#form"
              className="button fs-4 px-3 LP-headerButton"
            >
              {buttonTitle}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

type AboutWebinarProps = {
  title: string;
  description: string;
  onClick?: Function;
  buttonTitle?: string;
  pointsArray?: Array<string>;
};

function AboutWebinar({
  title,
  description,
  onClick,
  buttonTitle,
  pointsArray,
}: AboutWebinarProps) {
  return (
    <Row>
      <Col lg="3" md="3" className="hideOnMobile">
        <Image
          src={"/assets/images/raodmap2.png"}
          alt="roadmap"
          rounded
          className="h-75"
        />
      </Col>
      <Col lg="3" md="3" className="hideOnMobile ">
        <Image
          src={"/assets/images/raodmap/image2 .png"}
          alt="roadmap"
          rounded
          className="h-75"
        />
      </Col>
      <Col lg="6" md="6" sm="12" xs="12" className="px-5">
        {/* <p className='fs-5'>About the course</p> */}
        <h2 className="text-primary-color">{title}</h2>
        <p className="fs-5 mt-3 mb-5">{description}</p>
        {
          pointsArray?.map((item, index) => {
            return (
              <div className="d-flex align-items-center mb-1" key={index}>
                <i className="fa fa-check fs-4 text-primary-color mb-3"></i>
                <p className="fs-5 ms-2">{item}</p>
              </div>
            );
          })
        }
        <Button className="btn-black fs-3 mt-3 px-3 LP-AboutWebinarButton" href="#form">
          {buttonTitle}
        </Button>
      </Col>
    </Row>
  );
}

type WhyJoinProps = {
  array: Array<{ icon: string; title: string; text: string; image: string }>;
};

function WhyJoin({ array }: WhyJoinProps) {
  const defaultData = [
    {
      icon: "fa fa-calendar",
      title: "Upskill",
      text: "Future-proof and sharpen your cloud computing skills",
      image: "/assets/images/raodmap/challenge.png",
    },
    {
      icon: "fa fa-calendar",
      title: "Boost your career",
      text: "Gain insights into the job market for data professionals  and learn the technical and strategic skills",
      image: "/assets/images/raodmap/shuttle.png",
    },
    {
      icon: "fa fa-calendar",
      title: "Increase your earning potential",
      text: "Get better career opportunities with improved skills",
      image: "/assets/images/raodmap/revenue.png",
    },
    {
      icon: "fa fa-calendar",
      title: "Develop a winning data strategy ",
      text: "Learn how to build a roadmap for success in data and analytics ",
      image: "/assets/images/raodmap/ribbon.png",
    },
  ];

  const data = array?.length > 0 ? array : defaultData;

  return (
    <div className="whyJoin">
      <h2 className="text-primary-color">
        Why should<p className="text-primary-color">you join?</p>
      </h2>
      <Row className="justify-content-center mt-3 gap-4">
        {data?.map((item, index) => {
          return (
            <Col md="6" sm="12" xs="12" className="infoCard shadow" key={index}>
              <div className="infoCardTitle">
                <Image
                  src={item.image}
                  alt="goal"
                  rounded
                  className="iconImage"
                />
                <h3 className="text-white">{item.title}</h3>
                <div className="infoCardDivider" />
              </div>
              <p className="text-white fs-5 mt-3">{item.text}</p>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

type HowItHelpsProps = {
  skills: Array<{ iconImage: string; text: string }>;
  array?: Array<{ title: string; text: string; image: string }>;
};

function HowItHelps({ skills, array }: HowItHelpsProps) {
  const defaultData = [
    {
      title: "Future-proof career",
      text: "This webinar will future-proof your career and help you ace the tech world with new trends",
      image: "/assets/images/raodmap/goal.png",
    },
    {
      title: "Adaptability and resilience",
      text: "All the modules covered in this webinar will help you be adaptable to the ongoing challenges in the tech industry.",
      image: "/assets/images/raodmap/adapt.png",
    },
    {
      title: "Continuous learning",
      text: "The best opportunity to get along with tech trends.",
      image: "/assets/images/raodmap/continuous-learning.png",
    },
    {
      title: "Competitive advantage",
      text: "Learn the updated cloud computing strategies and advance your career.",
      image: "/assets/images/raodmap/advantage.png",
    },
  ];
  const data = array?.length > 0 ? array : defaultData;
  return (
    <div className="howItHelps ">
      <Row className="justify-content-center pt-4 helpRow gap-5">
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="align-items-center justify-content-center d-flex"
        >
          <h2 className="text-white  ">
            How will these<p className="text-white">courses help you?</p>
          </h2>
        </Col>
        {data.map((item, index) => {
          return (
            <Col sm="12" xs="12" key={index} className="infoCard shadow">
              <div className="infoCardTitle">
                <Image
                  src={item.image}
                  alt="roadmap"
                  rounded
                  className="iconImage"
                />
                <h3 className="text-white text-center ">{item.title}</h3>
                <div className="infoCardDivider" />
              </div>
              <p className="text-white fs-5 mt-3">{item.text}</p>
            </Col>
          );
        })}
      </Row>

      <Row className="helpLowerImage LP-SkillsContainer">
        <Col lg="6" md="6" className="p-5 pt-0 pb-0 hideOnMobile" />
        <Col lg="6" md="6">
          <h2 className="text-primary-color fs-2 mb-4">Skills</h2>
          <Row className="fs-5 text-black">
            {skills?.map((item, index) => {
              return (
                <Col lg="4" md="4" key={index} className="mb-4">
                  <li className="fw-bold fs-4 list-unstyled">
                    <img
                      src={item.iconImage}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    {item.text}
                  </li>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

type RegisterNowLandingProps = {
  data?: any;
  buttonTitle?: string;
  onClick?: Function;
  onClickUri?: string;
};
export function RegisterNowLanding({
  data,
  buttonTitle,
  onClick,
  onClickUri,
}: RegisterNowLandingProps) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigation = useNavigate();

  async function registerNow() {

    // check all fileds are filled
    if (name?.trim()?.length == 0 || email.length == 0 || phone.length == 0) {
      alert("Please fill all the fields");
      return;
    }

    if (email.length > 0) {
      const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!isEmailValid) {
        alert("Please enter a valid email");
        return;
      }
    }
    if (phone.length > 0) {
      const isPhoneValid = /^[0-9]{10}$/.test(phone);
      if (!isPhoneValid) {
        alert("Please enter a valid phone number");
        return;
      }
    }

    // other logics
    setLoading(true);
    await WebinerUserEmailCheck({ name, email, number: phone }).then((response) => {
      console.log('384', response);
      if (response) {
        navigation("/thanking-you");
      }
    });
    // ContactUs(name, email, phone, "message","nothing").then(response => {

    //   setName("");
    //   setEmail("");
    //   setPhone("");
    //   setLoading(false);
    //   if(response.status){
    //     alert("Thank you! We will get back to you soon.");
    //   }
    // });

  }

  return (
    <div id="form" className="registerNow p-4 p-lg-0 sideRib-registerForm">
      <Row className="registerFormImage">
        <Col lg="8" md="12" sm="12" xs="12" className="p-lg-5 pt-lg-0 pb-lg-0">
          <h2 className="text-primary-color ms-lg-5">
            <span className="text-black">Sign up</span> for the course
          </h2>
          <Form className="m-lg-5 mt-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                id="text-input-custom-style"
                placeholder="Enter your name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="text-input-custom-style"
                type="email"
                placeholder="Enter your email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                onChange={(e) => setPhone(e.target.value)}
                value={phone?.slice(0, 10)}
                maxLength={10}
                id="text-input-custom-style"
                type="number"
                placeholder="Enter your phone number"
                required
              />
            </Form.Group>
            <Button
              className="btn-black fs-4 px-4 LP-registerNowButton"
              href={onClickUri}
              onClick={() =>
                registerNow()
              }
              disabled={loading}
            >
              {" "}
              {loading
                ? "Loading..."
                : buttonTitle?.length > 0
                  ? buttonTitle
                  : `Enroll now for ₹ 99/-`}
            </Button>
          </Form>
        </Col>
        <Col lg="4" md="auto" className="hideOnMobile"></Col>
      </Row>
      {/* <Link className="button fs-sm-6 stickyButton m-2" to="tel: +918826724111">
        Call us at <i className="fa fa-phone mx-1 fs-5" /> +91-8826724111
      </Link> */}

      <Link style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        backgroundColor: "green",
        color: "white",
      }} className="btn" to="tel: +918826724111" >
        Call us at <i className="fa fa-phone mx-1 fs-5" /> 088267 24111
      </Link>

    </div>
  );
}

function Certificate() {
  return (
    <div className="certification">
      <Container>
        <Row>
          <Col
            lg="4"
            sm="12"
            className="d-flex justify-content-lg-center align-items-center pe-lg-5"
          >
            <h2 className="text-white certificationTitle">
              Get a completion certificate
            </h2>
          </Col>
          <Col lg="8" sm="12">
            <Image
              src={"/assets/images/roadmapCertificate.png"}
              alt="certificate"
              rounded
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export interface LPIinterface {
  aboutWebinar: AboutWebinarProps;
  whyJoin: WhyJoinProps;
  howItHelps: HowItHelpsProps;
  registerNow: RegisterNowLandingProps;
}

function RoadmapBody({
  aboutWebinar,
  whyJoin,
  howItHelps,
  registerNow,
}: LPIinterface) {
  return (
    <>
      <div className="sideRib-aboutWebinar">
        <ContainerBody
          className="aboutWebinarContainer LP-aboutWebinarContainer"
          children={<AboutWebinar {...aboutWebinar} />}
        />
      </div>
      <ContainerBody children={<WhyJoin {...whyJoin} />} />
      <HowItHelps {...howItHelps} />
      <RegisterNowLanding data={RoadmapCourse} {...registerNow} />
      <Certificate />
      <FeedbackView showTitle={true} />
      <FooterLandingPage />
    </>
  );
}

function LandingPageIndex({
  body,
  Head1,
  Head2,
}: {
  body: LPIinterface;
  Head1?: HeaderContentProps;
  Head2?: HeaderContentProps;
}) {

  useEffect(() => {
    // Inject the GTM script
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TZLWDX2N');
    `;
    document.head.appendChild(script);

    return () => {
      // Cleanup if necessary
      document.head.removeChild(script);
    };
  }, []);

  const mainContainerStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    margin: 'auto',
  };
  return (
    <div style={mainContainerStyle}>
      <div
        dangerouslySetInnerHTML={{
          __html: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZLWDX2N" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
        }}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <Layout />
      </div>
      <div className="roadmapMainContainer LP-roadmapMainContainer text-start">
        <HeaderContent {...Head1} />
        <ThereCards />
        <PartnerView dens={false} />
        <OurStory />
        <HeaderContent data={header2Data} {...Head2} />
        <RoadmapBody {...body} />
      </div>
    </div>
  );
}
export default LandingPageIndex;
